/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const { NODE_ENV } = process.env;

var adScript = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2790982044180181";
if (NODE_ENV === "development") {
  adScript = "";
}

function Seo({ description, lang, meta, keywords, title, imageSrc, imageWidth, imageHeight, imageAlt, url }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            googleSiteVerification
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const titleTemplate = title === "Home" ? site.siteMetadata.title : title + " - " + site.siteMetadata.title;

  const metaImage = [];
  if (imageSrc) {
    metaImage.push({
      property: "og:image",
      content: site.siteMetadata.siteUrl + imageSrc,
    });
    metaImage.push({
      property: "og:image:secure_url",
      content: site.siteMetadata.siteUrl + imageSrc,
    });
    metaImage.push({
      property: "twitter:image",
      content: site.siteMetadata.siteUrl + imageSrc,
    });
  }
  if (imageWidth) {
    metaImage.push({ property: "og:width", content: imageWidth });
  }
  if (imageHeight) {
    metaImage.push({ property: "og:height", content: imageHeight });
  }
  if (imageAlt) {
    metaImage.push({ property: "og:image:alt", content: imageAlt });
  }

  //titleTemplate={`%s - ${site.siteMetadata.title}`}
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl + url,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`, // summary
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:url`,
          content: site.siteMetadata.siteUrl + url,
        },
        {
          name: `google-site-verification`,
          content: site.siteMetadata.googleSiteVerification,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)
        .concat(metaImage)}
    >
      <script async src={adScript} crossorigin="anonymous"></script>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed&display=swap" rel="stylesheet" />
    </Helmet>
  );
}
//       <link href="https://fonts.googleapis.com/css2?family=Acme&family=Mochiy+Pop+One&family=BenchNine:wght@400;700&family=Saira+Extra+Condensed&display=swap" rel="stylesheet" />

// <script src="/script.js" type="text/javascript" />
//       <link href="https://fonts.googleapis.com/css2?family=Acme&display=swap" rel="stylesheet" />

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default Seo;
