import React from "react";
import DutchOvenLogo from "../assets/dutch-oven-final.svg";

export const cookbook_sections = [
  {
    name: "Beverages",
    menuName: "Beverages",
    link: "beverages",
    normalized: "beverage",
  },
  {
    name: "Breakfasts",
    menuName: "Breakfasts",
    link: "breakfasts",
    normalized: "breakfast",
  },
  {
    name: "Breakfasts for Dutch Oven",
    menuName: (
      <>
        Breakfasts <DutchOvenLogo className="dutch-oven-logo" height="20px" width="20px" />
      </>
    ),
    link: "dutch-oven-breakfasts",
    normalized: "dutch oven breakfast",
  },
  {
    name: "Breads and Rolls for Dutch Oven",
    menuName: (
      <>
        Breads & Rolls <DutchOvenLogo className="dutch-oven-logo" height="20px" width="20px" />
      </>
    ),
    link: "dutch-oven-breads",
    normalized: "dutch oven bread",
  },
  {
    name: "Desserts",
    menuName: "Desserts",
    link: "desserts",
    normalized: "dessert",
  },
  {
    name: "Desserts for Dutch Oven",
    menuName: (
      <>
        Desserts <DutchOvenLogo className="dutch-oven-logo" height="20px" width="20px" />
      </>
    ),
    link: "dutch-oven-desserts",
    normalized: "dutch oven dessert",
  },
  {
    name: "Main Meals",
    menuName: "Main Meals",
    link: "main-meals",
    normalized: "main meal",
  },
  {
    name: "Main Meals for Dutch Oven",
    menuName: (
      <>
        Main Meals <DutchOvenLogo className="dutch-oven-logo" height="20px" width="20px" />
      </>
    ),
    link: "dutch-oven-main-meals",
    normalized: "dutch oven main meal",
  },
  {
    name: "Sandwiches",
    menuName: "Sandwiches",
    link: "sandwiches",
    normalized: "sandwich",
  },
  {
    name: "Side Dishes",
    menuName: "Side Dishes",
    link: "side-dishes",
    normalized: "side dish",
  },
  { name: "Snacks", menuName: "Snacks", link: "snacks", normalized: "snack" },
  {
    name: "Soups and Stews",
    menuName: "Soups & Stews",
    link: "soups-and-stews",
    normalized: "soup",
  },
];

var cookbook_section_links_var = [];
cookbook_sections.map((rec) => (cookbook_section_links_var[rec.name] = rec.link));
export const cookbook_section_links = cookbook_section_links_var;

export const recipe_data = [
  {
    section: "Beverages",
    recipes: [
      {
        title: "Orangealicous",
        uri: "/recipes/view/orangealicous/",
        image: "orange-drink2",
        imageAlt: "Orange Drink",
        excerpt: "Delicious orange drink",
      },
      {
        title: "Razzle Dazzle",
        uri: "/recipes/view/razzle-dazzle/",
        image: "razzle-dazzle2",
        imageAlt: "Rasperries in white grape juice",
        excerpt: "Exotic raspberry drink",
      },
      {
        title: "Juice from the Sun",
        uri: "/recipes/view/juice-from-the-sun/",
        image: "orange-with-straw2",
        imageAlt: "Oracle with straw",
        excerpt: "Juice straight from the orange",
      },
      {
        title: "Radioactive Slime",
        uri: "/recipes/view/radioactive-slime/",
        image: "radioactive-slime-drink2",
        imageAlt: "Red drink with orange juice",
        excerpt: "Interesting fun beverage",
      },
      {
        title: "Orange Julius Camping Style",
        uri: "/recipes/view/orange-julius-camping-style/",
        image: "orange-julius2",
        imageAlt: "Orange Julius",
        excerpt: "Delicious at camp",
      },
      {
        title: "Hot Moomint",
        uri: "/recipes/view/hot-moomint/",
        image: "hot-chocolate2",
        imageAlt: "Hot Chocolate",
        excerpt: "Don't forget the marshmallows",
      },
    ],
  },
  {
    section: "Breakfasts",
    recipes: [
      {
        title: "Pancakes",
        uri: "/recipes/view/pancakes/",
        image: "pancakes2",
        imageAlt: "Pancakes",
        excerpt: "Yummy!",
      },
      {
        title: "Buttermilk Pancakes",
        uri: "/recipes/view/buttermilk-pancakes/",
        image: "blueberry-pancakes",
        imageAlt: "Blueberry pancakes",
        excerpt: "Blueberries or not",
      },
      {
        title: "Easy French Toast",
        uri: "/recipes/view/easy-french-toast/",
        image: "french-toast",
        imageAlt: "French Toast",
        excerpt: "It's a great breakfast",
      },
      {
        title: "Peachy French Toast",
        uri: "/recipes/view/peachy-french-toast/",
        image: "peach-french-toast2",
        imageAlt: "Peach French Toast",
        excerpt: "French toast with a twist",
      },
      {
        title: "He-Man French Toast",
        uri: "/recipes/view/he-man-french-toast/",
        image: "bodybuilder",
        imageAlt: "Body Builder graphic",
        excerpt: "Peanut butter sandwhich French toast",
      },
      {
        title: "Scrambled Eggs",
        uri: "/recipes/view/scrambled-eggs/",
        image: "scrambled-eggs",
        imageAlt: "Scrambled Eggs",
        excerpt: "Easy eggs are always great",
      },
      {
        title: "Egg Breakfast Muffin",
        uri: "/recipes/view/egg-breakfast-muffin/",
        image: "egg-muffin",
        imageAlt: "Egg Muffin Sandwich",
        excerpt: "Better than fastfood",
      },
      {
        title: "Eggs in a Bag",
        uri: "/recipes/view/eggs-in-a-bag/",
        image: "scrambled-eggs3",
        imageAlt: "Scrambled Eggs",
        excerpt: "Fun way to cook eggs at camp",
      },
      {
        title: "Egg in an Orange",
        uri: "/recipes/view/egg-in-an-orange/",
        image: "egg-in-orange",
        imageAlt: "Egg in Orange",
        excerpt: "Another fun way to cook an egg",
      },
      {
        title: "Hole in the Wall Eggs",
        uri: "/recipes/view/hole-in-the-wall-eggs/",
        image: "egg-toast2",
        imageAlt: "Toast with egg",
        excerpt: "Put the egg in the toast",
      },
      {
        title: "Breakfast Burrito",
        uri: "/recipes/view/breakfast-burrito/",
        image: "breakfast-burrito2",
        imageAlt: "Breakfast Burrito",
        excerpt: "Great with or without salsa",
      },
      {
        title: "Cinnamon Crescents",
        uri: "/recipes/view/cinnamon-crescents/",
        image: "crescent-rolls2",
        imageAlt: "Crescent Rolls",
        excerpt: "Great for breakfast or dessert",
      },
      {
        title: "Easy Grits",
        uri: "/recipes/view/easy-grits/",
        image: "southern-grits2",
        imageAlt: "Grits",
        excerpt: "A great tradition",
      },
    ],
  },
  {
    section: "Breakfasts for Dutch Oven",
    recipes: [
      {
        title: "Dutch Oven Fiesta Omelet",
        uri: "/recipes/view/dutch-oven-fiesta-omelet/",
        image: "fiesta-omelete2",
        imageAlt: "Omelet",
        excerpt: "Great omelet breakfast",
      },
      {
        title: "Dutch Oven Easy Sausage Egg Bake",
        uri: "/recipes/view/dutch-oven-easy-sausage-egg-bake/",
        image: "sausage-egg-bake",
        imageAlt: "Sausage and eggs",
        excerpt: "Only 5 ingredients",
      },
      {
        title: "Dutch Oven Ham & Cheese Egg Bake",
        uri: "/recipes/view/dutch-oven-ham-and-cheese-egg-bake/",
        image: "ham-and-eggs",
        imageAlt: "Ham and eggs",
        excerpt: "Oh so good!",
      },
      {
        title: "Dutch Oven Cheesy Hash Brown and Bacon Egg Bake",
        uri: "/recipes/view/dutch-oven-cheesy-hash-brown-and-bacon-egg-bake/",
        image: "dutch-oven-cheesy-hashbrowns2",
        imageAlt: "Cheesy eggs",
        excerpt: "Layered hashbrowns, eggs, cheese",
      },
      {
        title: "Dutch Oven Brat Bake",
        uri: "/recipes/view/brat-bake/",
        image: "brat-bake",
        imageAlt: "Brats",
        excerpt: "Brats, potatoes, apples, onions",
      },
      {
        title: "Dutch Oven Breakfast Pizza",
        uri: "/recipes/view/breakfast-pizza/",
        image: "breakfast-pizza",
        imageAlt: "Eggs",
        excerpt: "Eggs, dough, sausage, and more!",
      },
      {
        title: "Scrambled Eggs in Oven",
        uri: "/recipes/view/scrambled-eggs-in-oven/",
        image: "scrambled-eggs-in-oven2",
        imageAlt: "Scrambled Eggs",
        excerpt: "Easy, yummy eggs",
      },
      {
        title: "Biscuits on the Oven",
        uri: "/recipes/view/biscuits-on-the-oven/",
        image: "biscuits2",
        imageAlt: "Biscuits",
        excerpt: "Easy, yummy biscuits",
      },
      {
        title: "Biscuits and Gravy",
        uri: "/recipes/view/biscuits-and-gravy/",
        image: "biscuits-and-gravy2",
        imageAlt: "Biscuits and Gravy",
        excerpt: "A great tradition for some folks",
      },
      {
        title: "Huevos Rancheros",
        uri: "/recipes/view/huevos-rancheros/",
        image: "huevos-rancheros2",
        imageAlt: "Huevos Rancheros",
        excerpt: "Eggs, tomatoes, sausage",
      },
      {
        title: "Dutch Oven Pancake",
        uri: "/recipes/view/dutch-oven-pancakes/",
        image: "pancakes3",
        imageAlt: "Pancakes",
        excerpt: "Everyone's favorite",
      },
    ],
  },
  {
    section: "Breads and Rolls for Dutch Oven",
    recipes: [
      {
        title: "Monkey Bread",
        uri: "/recipes/view/monkey-bread/",
        image: "monkey-bread2",
        imageAlt: "Monkey Bread",
        excerpt: "Easy, yummy bread",
      },
      {
        title: "Nutty Cinnamon Crescent Rolls",
        uri: "/recipes/view/nutty-cinnamon-crescent-rolls/",
        image: "nutty-cinnamon-roll2",
        imageAlt: "Nutty Cinnamon Rolls",
        excerpt: "Nuts are optional",
      },
      {
        title: "Lazy Scout Rolls",
        uri: "/recipes/view/lazy-scout-rolls/",
        image: "lazy-scout-buns2",
        imageAlt: "Cinnamon Rolls",
        excerpt: "Easy cinnamon buns",
      },
      {
        title: "Orange Oatmeal Coffee Cake",
        uri: "/recipes/view/orange-oatmeal-coffee-cake/",
        image: "orange-oatmeal-coffee-cake2",
        imageAlt: "Orange Coffee Cake",
        excerpt: "Nice afternoon snack",
      },
    ],
  },
  {
    section: "Desserts",
    recipes: [
      {
        title: "Smores",
        uri: "/recipes/view/smores/",
        image: "smores2",
        imageAlt: "Smores",
        excerpt: "Always a hit",
      },
      {
        title: "Smakoes",
        uri: "/recipes/view/smakoes/",
        image: "tortilla-chocolate2",
        imageAlt: "Tortilla with chocolate",
        excerpt: "Like a crepe",
      },
      {
        title: "Ice Cream in a Bag",
        uri: "/recipes/view/ice-cream-in-a-bag/",
        image: "ice-cream2",
        imageAlt: "Ice Cream",
        excerpt: "Another fun camp activitiy",
      },
      {
        title: "Pudding Cones",
        uri: "/recipes/view/pudding-cones/",
        image: "pudding-cone",
        imageAlt: "Pudding Cone",
        excerpt: "Try pudding instead",
      },
      {
        title: "Oreo Pudding Parfait",
        uri: "/recipes/view/oreo-pudding-parfait/",
        image: "oreo-pudding2",
        imageAlt: "Oreo Pudding Parfait",
        excerpt: "Easy, fun, good",
      },
      {
        title: "Yogurt Parfait",
        uri: "/recipes/view/yogurt-parfait/",
        image: "yogurt-parfait",
        imageAlt: "Yogurt Parfait",
        excerpt: "Good & healthy",
      },
      {
        title: "Fruit Whip",
        uri: "/recipes/view/fruit-whip/",
        image: "fruit-whip",
        imageAlt: "Fruit Whip Bowl",
        excerpt: "Simply delicious",
      },
      {
        title: "Creamy Fruit Salad",
        uri: "/recipes/view/creamy-fruit-salad/",
        image: "fruit-pudding2",
        imageAlt: "Fruit pudding",
        excerpt: "Pudding and fruit",
      },
      {
        title: "Oreo Energy Balls",
        uri: "/recipes/view/oreo-energy-balls/",
        image: "oreo-balls",
        imageAlt: "Oreo Balls",
        excerpt: "Oreos and peanut butter",
      },
      {
        title: "Moose Droppings",
        uri: "/recipes/view/moose-droppings/",
        image: "moose",
        imageAlt: "Moose in the field",
        excerpt: "Moose dropping-like non-bake cookie ",
      },
      {
        title: "Icky Sticky Tootsie Rolls",
        uri: "/recipes/view/icky-sticky-tootsie-rolls/",
        image: "chocolates-candies2",
        imageAlt: "Chocolate Candies",
        excerpt: "Homemade Tootside Rolls",
      },
      {
        title: "Alpine Peaches and Pears",
        uri: "/recipes/view/alpine-peaches-and-pears/",
        image: "alpine-peaches2",
        imageAlt: "Alpine peaches",
        excerpt: "Peaches, pears, mandarin oranges",
      },
      {
        title: "Pan Pies",
        uri: "/recipes/view/pan-pies/",
        image: "pan-pies",
        imageAlt: "Pan fried pies",
        excerpt: "Pan fried pies",
      },
      {
        title: "Pie Iron Pies",
        uri: "/recipes/view/pie-iron-pies/",
        image: "pie-iron",
        imageAlt: "Pie Iron",
        excerpt: "It's fun to use a pie iron",
      },
      {
        title: "Banana Boats",
        uri: "/recipes/view/banana-boats/",
        image: "banana-boats2",
        imageAlt: "Banana boats on bed of coals",
        excerpt: "Great around the fire",
      },
      {
        title: "Black & White Soda",
        uri: "/recipes/view/black-and-white-soda/",
        image: "black-and-white-soda2",
        imageAlt: "Black and White Soda",
        excerpt: "Chocolate, ice cream, soda",
      },
    ],
  },
  {
    section: "Desserts for Dutch Oven",
    recipes: [
      {
        title: "Fruit Cobbler",
        uri: "/recipes/view/fruit-cobbler-peach-apple-cherry-blueberry/",
        image: "fruit-cobbler2",
        imageAlt: "Fruit Cobbler",
        excerpt: "Easy Dutch oven dessert",
      },
      {
        title: "Pineapple Upside Down Cake",
        uri: "/recipes/view/pineapple-upside-down-cake/",
        image: "pineapple-upside-down-cake2",
        imageAlt: "Pineapple with Cherries",
        excerpt: "Cook it upside down",
      },
      {
        title: "Apple Spice Cake",
        uri: "/recipes/view/apple-spice-cake/",
        image: "apple-spice-cake",
        imageAlt: "Apple Spice Cake",
        excerpt: "Super good",
      },
      {
        title: "Caramel Apple Crisp",
        uri: "/recipes/view/caramel-apple-crisp/",
        image: "caramel-apple-crisp",
        imageAlt: "Caramel Apple Crisp",
        excerpt: "Caramel on the inside",
      },
      {
        title: "Cherry Crisp Cobbler",
        uri: "/recipes/view/cherry-crisp-cobbler/",
        image: "cherry-cobbler",
        imageAlt: "Cherry Cobbler",
        excerpt: "Serve with vanilla ice cream if you can",
      },
      {
        title: "Chocolate Covered Cherry Cake",
        uri: "/recipes/view/chocolate-covered-cherry-cake/",
        image: "chocolate-covered-cherry-cake2",
        imageAlt: "Chocolate Cake with Cherries",
        excerpt: "Top it off with fresh cherries on top",
      },
      {
        title: "One Big Smore",
        uri: "/recipes/view/one-big-smore/",
        image: "big-smore2",
        imageAlt: "Pan with Chocolatee",
        excerpt: "A very large smore",
      },
      {
        title: "Dutch Oven Pies",
        uri: "/recipes/view/dutch-oven-pies/",
        image: "dutch-oven-apple-pie2",
        imageAlt: "Apple Pie in Dutch Oven",
        excerpt: "Easy as pie",
      },
      {
        title: "Dutch Oven Cookies",
        uri: "/recipes/view/dutch-oven-cookies/",
        image: "dutch-oven-cookies",
        imageAlt: "Chocolate Chip Cookies",
        excerpt: "Fresh cookies while camping",
      },
      {
        title: "Dutch Oven Donuts",
        uri: "/recipes/view/dutch-oven-donuts/",
        image: "dutch-oven-donuts2",
        imageAlt: "Donuts",
        excerpt: "Donuts!",
      },
      {
        title: "Dutch Oven Deep Frying",
        uri: "/recipes/view/dutch-oven-deep-frying/",
        image: "deep-frying",
        imageAlt: "Deep Frying",
        excerpt: "Fry onion rings or just about anything",
      },
    ],
  },
  {
    section: "Main Meals",
    recipes: [
      {
        title: "Hamburgers",
        uri: "/recipes/view/hamburgers/",
        image: "hamburger",
        imageAlt: "Hamburger",
        excerpt: "Easy peasy hamburgers",
      },
      {
        title: "Hot Dogs or Brats",
        uri: "/recipes/view/hot-dogs-or-brats/",
        image: "hot-dogs2",
        imageAlt: "Hot dogs",
        excerpt: "Always a hit",
      },
      {
        title: "Pigs in a Sleeping Bag",
        uri: "/recipes/view/pigs-in-a-sleeping-bag/",
        image: "pigs-in-blanket2",
        imageAlt: "Wrapped hot dogs",
        excerpt: "Hot dogs wrapped crescent rolls",
      },
      {
        title: "Dogs in a Straight Jacket",
        uri: "/recipes/view/dogs-in-a-straight-jacket/",
        image: "hot-dog-bacon",
        imageAlt: "Hot dogs wrapped in bacon",
        excerpt: "Hot dogs wrapped in bacon",
      },
      {
        title: "Pork & Beans",
        uri: "/recipes/view/pork-and-beans/",
        image: "pork-and-beans2",
        imageAlt: "Pork and beans",
        excerpt: "Pork, beans, and hot dogs",
      },
      {
        title: "Simple Tacos",
        uri: "/recipes/view/simple-tacos/",
        image: "tacos2",
        imageAlt: "Tacos",
        excerpt: "Easy tacos",
      },
      {
        title: "Tacos in a Bag",
        uri: "/recipes/view/tacos-in-a-bag/",
        image: "doritos2",
        imageAlt: "Doritos bag",
        excerpt: "Fun & easy for camping",
      },
      {
        title: "Mac & Cheese",
        uri: "/recipes/view/mac-and-cheese/",
        image: "macaroni-and-cheese2",
        imageAlt: "Macaroni and cheese",
        excerpt: "Easy from a box",
      },
      {
        title: "Fiesta Mac and Cheese",
        uri: "/recipes/view/fiesta-mac-and-cheese/",
        image: "fiesta-mac-and-cheese2",
        imageAlt: "Fiesta Mac and Cheese",
        excerpt: "Mac and cheese on steroids",
      },
      {
        title: "Easy Spaghetti",
        uri: "/recipes/view/easy-spaghetti/",
        image: "spaghetti2",
        imageAlt: "Spaghetti",
        excerpt: "Great with French bread",
      },
      {
        title: "Beefy Pasta",
        uri: "/recipes/view/beefy-pasta/",
        image: "beefy-pasta",
        imageAlt: "Beefy Pasta",
        excerpt: "Noodles, beef, sauce",
      },
      {
        title: "Easy Chicken Marinara",
        uri: "/recipes/view/easy-chicken-marinara/",
        image: "chicken-marinara3",
        imageAlt: "Chicken Marinara",
        excerpt: "Chicken, pasta, sauce",
      },
      {
        title: "Foil Dinner",
        uri: "/recipes/view/foil-dinner/",
        image: "foil-dinner2",
        imageAlt: "Foil dinner over campfire",
        excerpt: "Great campfire meal",
      },
      {
        title: "Chicken Fajitas",
        uri: "/recipes/view/chicken-fajitas/",
        image: "chicken-fajita2",
        imageAlt: "Chicken Fajita",
        excerpt: "Chicken, tortillas, extras",
      },
      {
        title: "Teriyaki Beef Kabobs",
        uri: "/recipes/view/teriyaki-beef-kabobs/",
        image: "kabob",
        imageAlt: "Kabobs",
        excerpt: "Plan ahead for these",
      },
      {
        title: "Grilled Chicken Breast",
        uri: "/recipes/view/grilled-chicken-breast/",
        image: "chicken-breast-grill",
        imageAlt: "chicken breast on grill",
        excerpt: "Easy chicken",
      },
      {
        title: "Spam",
        uri: "/recipes/view/spam/",
        image: "spam",
        imageAlt: "Spam can",
        excerpt: "Spam goes with anything",
      },
      {
        title: "Easy Chili Out of the Can",
        uri: "/recipes/view/easy-chili-out-of-the-can/",
        image: "chili-from-a-can2",
        imageAlt: "Bowl of chili",
        excerpt: "Easy easy chili",
      },
    ],
  },
  {
    section: "Main Meals for Dutch Oven",
    recipes: [
      {
        title: "High Adventure Macaroni",
        uri: "/recipes/view/high-adventure-macaroni/",
        image: "squirrel",
        imageAlt: "Squirrel",
        excerpt: "Why settle for simple macaroni?",
      },
      {
        title: "Baked Spaghetti",
        uri: "/recipes/view/baked-spaghetti/",
        image: "baked-spaghetti2",
        imageAlt: "Baked Spaghetti",
        excerpt: "Great spaghetti dinner",
      },
      {
        title: "Hungarian Goulash",
        uri: "/recipes/view/hungarian-goulash/",
        image: "goulash2",
        imageAlt: "Goulash",
        excerpt: "Worth the effort",
      },
      {
        title: "Wagon Master Camp Stew",
        uri: "/recipes/view/wagon-master-camp-stew/",
        image: "wagon-master-stew2",
        imageAlt: "Wagaon Master Stew",
        excerpt: "Be prepared!",
      },
      {
        title: "Stuffed Meat Loaf",
        uri: "/recipes/view/stuffed-meat-loaf/",
        image: "stuffed-meatloaf2",
        imageAlt: "Stuffed Meatloaf",
        excerpt: "Meatloaf with onions an corn",
      },
      {
        title: "Easy Cheesy Meat and Potatoes",
        uri: "/recipes/view/easy-cheesy-meat-and-potatoes/",
        image: "easy-cheesy-meat-potatoes2",
        imageAlt: "Cheesy Potatoes",
        excerpt: "Tater tots, hamburger, yum",
      },
      {
        title: "Yummy Potatoes",
        uri: "/recipes/view/yummy-potatoes/",
        image: "yummy-potatoes2",
        imageAlt: "Potatoes",
        excerpt: "Hamburger, potatoes, cheese, ...",
      },
      {
        title: "Taco Casserole",
        uri: "/recipes/view/taco-casserole/",
        image: "taco-casserole2",
        imageAlt: "Taco Casserole",
        excerpt: "Easy camp cooking",
      },
      {
        title: "Corn Casserole",
        uri: "/recipes/view/corn-casserole/",
        image: "corn-casserole2",
        imageAlt: "Corn Casserole",
        excerpt: "Corn, eggs, bread, cheese, yum!",
      },
      {
        title: "Chicken and Rice",
        uri: "/recipes/view/chicken-and-rice/",
        image: "chicken-breaded2",
        imageAlt: "Chicken Breaded",
        excerpt: "Breaded chicen with creamy rice",
      },
      {
        title: "Pepsi Chicken",
        uri: "/recipes/view/pepsi-chicken/",
        image: "pepsi-chicken2",
        imageAlt: "BBQ Chicken Legs",
        excerpt: "Delicious chicken",
      },
      {
        title: "Chicken Stew",
        uri: "/recipes/view/chicken-stew/",
        image: "chicken-stew2",
        imageAlt: "Chicken Stew",
        excerpt: "Chicken, potatoes, carrots, etc.",
      },
      {
        title: "Chicken and Vegetables",
        uri: "/recipes/view/chicken-and-vegetables/",
        image: "chicken-and-vegetables2",
        imageAlt: "Chicken and Vegetables",
        excerpt: "Chicken, tomatoes, peas",
      },
      {
        title: "Today's Catch",
        uri: "/recipes/view/todays-catch/",
        image: "fish2",
        imageAlt: "Fried Fish",
        excerpt: "Easy fried fish",
      },
      {
        title: "Poor Man's Lobster",
        uri: "/recipes/view/poor-mans-lobster/",
        image: "poor-mans-lobster2",
        imageAlt: "Fish and potatoes",
        excerpt: "Just use fish",
      },
    ],
  },
  {
    section: "Sandwiches",
    recipes: [
      {
        title: "Grilled Cheese",
        uri: "/recipes/view/grilled-cheese/",
        image: "grilled-cheese",
        imageAlt: "Grilled cheese sandwich",
        excerpt: "Great with tomato soup",
      },
      {
        title: "Bacon, Lettuce & Tomato",
        uri: "/recipes/view/bacon-lettuce-tomato/",
        image: "blt2",
        imageAlt: "Bacon, lettuce, tomoto sandwich",
        excerpt: "Did we mention bacon?",
      },
      {
        title: "Reuben",
        uri: "/recipes/view/rueben/",
        image: "reuben2",
        imageAlt: "Reuben sandwich",
        excerpt: "Corn beef sandwich",
      },
      {
        title: "Sandwich Melts",
        uri: "/recipes/view/sandwich-melts/",
        image: "roast-beef-sandwich",
        imageAlt: "Roast beef sandwich",
        excerpt: "Beef, cheese, bread",
      },
      {
        title: "Philly Cheese Steak",
        uri: "/recipes/view/philly-cheese-steak/",
        image: "french-dip",
        imageAlt: "French dip sandwich",
        excerpt: "Straight from Philadelphia, or close",
      },
      {
        title: "Easy Sloppy Joes",
        uri: "/recipes/view/easy-sloppy-joes/",
        image: "sloppy-joe",
        imageAlt: "Sloppy Joe",
        excerpt: "Always great",
      },
      {
        title: "Easy BBQ Beef",
        uri: "/recipes/view/easy-bbq-beef/",
        image: "bbq-beef",
        imageAlt: "BBQ beef sandwiches",
        excerpt: "Buns & beef, add cheese if desired",
      },
      {
        title: "Pita Pizzas",
        uri: "/recipes/view/pita-pizzas/",
        image: "pita-pocket2",
        imageAlt: "Pita pockets",
        excerpt: "Pizza at camp!",
      },
    ],
  },
  {
    section: "Side Dishes",
    recipes: [
      {
        title: "Boiled Corn on the Cob",
        uri: "/recipes/view/boiled-corn-on-the-cob/",
        image: "corn",
        imageAlt: "Corn on the cob",
        excerpt: "Great with butter & salt",
      },
      {
        title: "Corn Roast",
        uri: "/recipes/view/corn-roast/",
        image: "corn-roasted2",
        imageAlt: "Roasted corn on the cob",
        excerpt: "Roast or grill",
      },
      {
        title: "Roasted Potatoes",
        uri: "/recipes/view/roasted-potatoes/",
        image: "roasted-potatoes2",
        imageAlt: "Roasted potatoes",
        excerpt: "Gread side dish",
      },
      {
        title: "American Fries",
        uri: "/recipes/view/american-fries/",
        image: "american-fries2",
        imageAlt: "Sliced potatoes on frying pan",
        excerpt: "Fun to make and eat",
      },
      {
        title: "Mashed Potatoes",
        uri: "/recipes/view/mashed-potatoes/",
        image: "mashed-potatoes",
        imageAlt: "Mashed potatoes",
        excerpt: "Oh so creamy",
      },
      {
        title: "Baked Potato on Campfire",
        uri: "/recipes/view/baked-potato-on-campfire/",
        image: "baked-potatoes2",
        imageAlt: "baked potato",
        excerpt: "Don't forget the sour cream and salt",
      },
      {
        title: "Cool Whip Fruit Salad",
        uri: "/recipes/view/cool-whip-fruit-salad/",
        image: "cool-whip-fruit-salad2",
        imageAlt: "Cool whip fruit salad",
        excerpt: "Like a dessert",
      },
      {
        title: "Snicker Salad",
        uri: "/recipes/view/snicker-salad/",
        image: "snicker-salad2",
        imageAlt: "Snicker salad",
        excerpt: "Or is it a dessert?",
      },
    ],
  },
  {
    section: "Snacks",
    recipes: [
      {
        title: "Trail Mix",
        uri: "/recipes/view/trail-mix/",
        image: "trail-mix2",
        imageAlt: "Trail mix",
        excerpt: "Great for hiking",
      },
      {
        title: "Ticks on a Toilet Seat",
        uri: "/recipes/view/ticks-on-a-toilet-seat/",
        image: "apple-peanut-butter2",
        imageAlt: "Apples with peanut butter",
        excerpt: "Apples with peanut butter and raisins",
      },
      {
        title: "Ants on a Log",
        uri: "/recipes/view/ants-on-a-log/",
        image: "ants-on-a-log2",
        imageAlt: "Celery, cream cheese, raisins",
        excerpt: "Lots of fun of course",
      },
      {
        title: "Nachos on a Campfire",
        uri: "/recipes/view/nachos-on-a-campfire/",
        image: "nachos2",
        imageAlt: "Nachos",
        excerpt: "Nachos by the fire",
      },
      {
        title: "Creamy Fruit Dip",
        uri: "/recipes/view/creamy-fruit-dip/",
        image: "fruit-dip2",
        imageAlt: "Fruit dip",
        excerpt: "Use fruit or veggies",
      },
      {
        title: "Easy Hot Cheese Dip",
        uri: "/recipes/view/easy-hot-cheese-dip/",
        image: "cheese-dip2",
        imageAlt: "Cheese Dip",
        excerpt: "Easy for camping",
      },
      {
        title: "Roasted Apples",
        uri: "/recipes/view/roasted-apples/",
        image: "roasted-apples2",
        imageAlt: "Roasted apples",
        excerpt: "Fun & easy",
      },
    ],
  },
  {
    section: "Soups and Stews",
    recipes: [
      {
        title: "Mulligan Soup",
        uri: "/recipes/view/mulligan-soup/",
        image: "mulligan-soup2",
        imageAlt: "Mulligan Soup ",
        excerpt: "Easy camp soup",
      },
      {
        title: "Cowboy Soup",
        uri: "/recipes/view/cowboy-soup/",
        image: "cowboy-soup2",
        imageAlt: "Cowboy soup",
        excerpt: "Hearty soup",
      },
      {
        title: "Golden Nugget Soup",
        uri: "/recipes/view/golden-nugget-soup/",
        image: "golden-nugget-soup2",
        imageAlt: "Golden Nugget Soup",
        excerpt: "Tomato soup with breaded cheese balls",
      },
      {
        title: "2X4 Soup",
        uri: "/recipes/view/2x4-soup/",
        image: "2x4-soup2",
        imageAlt: "2x4 soup",
        excerpt: "Good, easy soup",
      },
      {
        title: "Jambalaya",
        uri: "/recipes/view/jambalaya/",
        image: "jambalaya2",
        imageAlt: "Jambalaya",
        excerpt: "A bit of work, but worth it",
      },
      {
        title: "Porcupine Soup",
        uri: "/recipes/view/porcupine-soup/",
        image: "porcupine-soup",
        imageAlt: "Porcupine Soup",
        excerpt: "Meatball tomato soup",
      },
      {
        title: "Minestrone",
        uri: "/recipes/view/minestone/",
        image: "minestrone2",
        imageAlt: "Minestrone Soup",
        excerpt: "Easy camp soup",
      },
      {
        title: "Quick and Easy Skillet Stew",
        uri: "/recipes/view/quick-and-easy-skillet-stew/",
        image: "skillet-stew2",
        imageAlt: "Stew",
        excerpt: "Another great & easy recipe",
      },
      {
        title: "Road Kill Stew",
        uri: "/recipes/view/road-kill-stew/",
        image: "road-skill-stew2",
        imageAlt: "Meat Stew",
        excerpt: "Fun stew",
      },
      {
        title: "Beanless Chili",
        uri: "/recipes/view/beanless-chili/",
        image: "chili3a",
        imageAlt: "Chili",
        excerpt: "Meaty & hearty",
      },
    ],
  },
];
