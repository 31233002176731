import React, { useState, useEffect, useRef } from "react";
import { navigate } from "gatsby";

import { Navbar, Nav, Form, Button } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import Logo from "../assets/logo-fire2-final.svg";
import Pluralize from "../../experimental/pluralize";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { FaBars, FaCaretDown } from "react-icons/fa";
import {
  SignIn,
  SignUp,
  ForgotPassword,
  signOut,
  isUserSignedIn,
  isUserAdmin,
  rehydrateUserIfChanged,
} from "./accountManagement";
import { store } from "../redux-store";
import { renewTokenIfNeeded } from "./api";

import { cookbook_sections } from "../components/cookbookData";
import { setDoShowSignIn } from "../actions/user-action";

function normalize_search_text(search_text) {
  var out_text = "";
  if (search_text !== undefined) {
    var stringArray = search_text.split(/(\s+)/);
    for (var i = 0; i < stringArray.length; i++) {
      if (stringArray[i] !== " ") {
        //console.log('checking word: X:'+stringArray[i]+':X => ' + Pluralize.singular(stringArray[i]))
        out_text = out_text + Pluralize.singular(stringArray[i]) + " ";
      }
    }
  }
  return out_text;
}

const keyword_map = [
  { keyword: "about", link: "/about/" },
  { keyword: "camping", link: "/cookbook/" },
  { keyword: "camping cookbook", link: "/cookbook/" },
  { keyword: "cookbook", link: "/cookbook/" },
  { keyword: "stew", link: "/cookbook/soups-and-stews/" },
  { keyword: "dutch oven", link: "/dutch-oven-cooking-tips/" },
  { keyword: "lunch", link: "/cookbook/main-meals/" },
  { keyword: "supper", link: "/cookbook/main-meals/" },
  { keyword: "dinner", link: "/cookbook/main-meals/" },
];

const CustomNavbar = ({ pageInfo, additionalNavBar }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isAccountModalOpen, setIsAccountModalOpen] = React.useState(false);
  const [accountModalContentType, setAccountModalContentType] = React.useState("signin");
  const [isTopMenuOpen, setIsTopMenuOpen] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);

  const userData = useSelector((state) => state.userData);

  useEffect(() => {
    setSearchQuery(pageInfo?.searchQuery);
  }, [pageInfo?.searchQuery]);

  useEffect(() => {
    //need to use a short timeout here otherwise this check interferes with the sign in flow
    //without the timeout, the rehydrate will hydrate from store and wipe out the sign in
    //const onTimeOut = async () => {
    //  console.log("in navBar onTimeOut");
    // 11/12 - removed timeout now that we use lastUpdate on the user data
    const checkUser = async () => {
      const localUserData = await rehydrateUserIfChanged(store, userData);
      //console.log('navBar checkUser  - accessToken='+localUserData.accessToken);
      const result = renewTokenIfNeeded(localUserData, store);
      if (result.status === "expired") {
        signOut(() => {});
      }
    };
    //setTimeout(onTimeOut, 100);
    checkUser();
  }, [userData]);

  useEffect(() => {
    if (userData.doShowSignIn) {
      showSignIn();
      store.dispatch(setDoShowSignIn(false));
    }
  }, [userData]);

  const doSearch = (e) => {
    //history.push(`?s=${searchQuery}`)
    e.preventDefault();
    if (searchQuery !== undefined) {
      const normalized_query = normalize_search_text(searchQuery.toLowerCase()).trim();
      console.log("normalized query=" + normalized_query);
      const cookbook_match = cookbook_sections.find((section) => section.normalized === normalized_query);
      const keyword_match = keyword_map.find((item) => item.keyword === normalized_query);

      if (keyword_match !== undefined) {
        navigate(keyword_match.link, { state: { searchQuery } });
      } else if (cookbook_match !== undefined) {
        navigate("/cookbook/" + cookbook_match.link + "/", {
          state: { searchQuery },
        });
      } else {
        navigate("/search", {
          state: { searchQuery, normalizedQuery: normalized_query },
        });
      }
    }
  };

  const AccountModal = () => {
    return (
      <>
        <div className="d-flex justify-content-end">
          <Button onClick={hideAccountModal} variant="link">
            <FaTimes />
          </Button>
        </div>
        {accountModalContentType === "signin" ? (
          <SignIn
            onSignIn={onSignIn}
            signUpClick={() => showSignUp()}
            forgotPasswordClick={showForgotPassword}
            showLogo={true}
          />
        ) : accountModalContentType === "signup" ? (
          <SignUp onSignUp={onSignUp} signInClick={showSignIn} showLogo={true} />
        ) : (
          <ForgotPassword onForgotPassword={onForgotPassword} signInClick={showSignIn} showLogo={true} />
        )}
      </>
    );
  };

  const showSignIn = () => {
    setAccountModalContentType("signin");
    setIsAccountModalOpen(true);
    setIsTopMenuOpen(false);
  };

  const showForgotPassword = () => {
    setAccountModalContentType("forgotpassword");
    setIsAccountModalOpen(true);
    setIsTopMenuOpen(false);
  };

  const showSignUp = () => {
    setAccountModalContentType("signup");
    setIsAccountModalOpen(true);
    setIsTopMenuOpen(false);
  };

  const hideAccountModal = () => {
    setIsAccountModalOpen(false);
  };

  const signOutClick = () => {
    console.log("signOut");
    signOut(() => setIsAccountMenuOpen(false));
  };

  const onSignIn = () => {
    setIsAccountModalOpen(false);
    console.log("token=" + userData.accessToken);
  };

  const onSignUp = () => {
    setIsAccountModalOpen(false);
  };

  const onForgotPassword = () => {
    setIsAccountModalOpen(false);
  };

  const toggleTopMenu = () => {
    setIsTopMenuOpen(!isTopMenuOpen);
  };

  const menuLink = (navLink) => {
    console.log("in menuLink");
    setIsTopMenuOpen(false);
    navigate(navLink);
  };

  const toggleAccountMenu = () => {
    setIsAccountMenuOpen(!isAccountMenuOpen);
  };

  const TopMenu = () => {
    return isTopMenuOpen ? (
      <div>
        <div id="basic-navbar-nav" className="d-flex flex-column justify-content-center flex-shrink-1">
          {isUserSignedIn(userData) ? (
            <>
              <div className="d-flex justify-content-center signed-in-as-title">Signed in as</div>
              <div className="d-flex justify-content-center signed-in-as-user">
                <b>{format_user_name(userData.userName)}</b>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="d-flex justify-content-end nav-link">
            <Button variant="link" className="local-link nav-link" onClick={() => menuLink("/")}>
              Home
            </Button>
          </div>
          <div className="d-flex justify-content-end nav-link">
            <Button variant="link" className="local-link nav-link" onClick={() => menuLink("/cookbook")}>
              Cookbook
            </Button>
          </div>
          <div className="flex-grow-1 flex-fill navbar-menu-line"></div>
          {isUserSignedIn(userData) ? (
            <>
              <div className="d-flex justify-content-end nav-link">
                <Button variant="link" className="local-link nav-link" onClick={clickSavedRecipes}>
                  Saved Recipes
                </Button>
              </div>
              <div className="d-flex justify-content-end nav-link">
                <Button variant="link" className="local-link nav-link" onClick={() => menuLink("/account")}>
                  Account Settings
                </Button>
              </div>
              {isUserAdmin(userData) ? (
                <div className="d-flex justify-content-end nav-link">
                <Button variant="link" className="local-link nav-link" onClick={() => menuLink("/admin")}>
                  Admin
                </Button>
                </div>
              ) : (
                <></>
              )}
              <div className="flex-grow-1 flex-fill navbar-menu-line"></div>
              <div className="d-flex justify-content-end nav-link">
                <Button variant="link" className="local-link nav-link" onClick={signOutClick}>
                  Sign Out
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-end nav-link">
                <Button variant="link" className="local-link nav-link" onClick={showSignIn}>
                  Sign In
                </Button>
              </div>
              <div className="d-flex justify-content-end nav-link">
                <Button variant="link" className="local-link nav-link" onClick={showSignUp}>
                  Sign Up
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const format_user_name = (userName) => {
    return userName;
    /*
    if (userName.length < 15) {
      return userName.length; 
    } else {
      return userName.substring(0,15)+"...";
    }
  */
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsAccountMenuOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const AccountMenu = () => {
    return isAccountMenuOpen ? (
      <div ref={wrapperRef} id="account-menu-container">
        <div id="account-menu">
          <div id="basic-navbar-nav" className="d-flex flex-column justify-content-center flex-shrink-1">
            <div className="d-flex justify-content-center signed-in-as-title">Signed in as</div>
            <div className="d-flex justify-content-center signed-in-as-user ps-2 pe-2">
              <b>{format_user_name(userData.userName)}</b>
            </div>
            <div className="flex-grow-1 flex-fill navbar-menu-line"></div>
            <div className="d-flex justify-content-end nav-link">
              <Button variant="link" className="local-link nav-link" onClick={() => menuLink("/account/saved-recipes")}>
                Saved Recipes
              </Button>
            </div>
            <div className="d-flex justify-content-end nav-link">
              <Button variant="link" className="local-link nav-link" onClick={() => menuLink("/account")}>
                Account Settings
              </Button>
            </div>
            {isUserAdmin(userData) ? (
              <div className="d-flex justify-content-end nav-link">
                <Button variant="link" className="local-link nav-link" onClick={() => menuLink("/admin")}>
                  Admin
                </Button>
              </div>
            ) : (
              <></>
            )}
            <div className="flex-grow-1 flex-fill navbar-menu-line"></div>
            <div className="d-flex justify-content-end nav-link">
              <Button variant="link" className="local-link nav-link" onClick={signOutClick}>
                Sign Out
              </Button>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const clickSavedRecipes = () => {
    if (isUserSignedIn(userData)) {
      setIsTopMenuOpen(false);
      navigate("/account/saved-recipes");
    } else {
      showSignIn();
    }
  };

  return (
    <>
      <div id="site-navbar5">
        <Navbar expand="md" id="site-navbar-search">
          <div className="d-flex flex-column  flex-grow-1">
            <div className="d-flex flex-grow-1">
              <div className="d-flex justify-content-between flex-grow-1">
                <div className="d-flex">
                  <Navbar.Brand id="navbar-logo1-container" href="/" className="pb-1 pt-0 me-0">
                    <div className="d-flex justify-content-center flex-grow-1">
                      <Logo id="navbar-logo1" width="50px" height="50px" />
                      <div className="d-flex flex-column flex-grow-1">
                        <div id="nav-logo-text1a">I Love It</div>
                        <div id="nav-logo-text1b">Camping</div>
                      </div>
                    </div>
                  </Navbar.Brand>
                  <Navbar.Brand id="navbar-logo2-container" href="/" className="pt-0">
                    <div className="d-flex justify-content-center flex-grow-1">
                      <Logo id="navbar-logo2" width="50px" height="50px" />
                      <div id="nav-logo-text2">I Love It Camping</div>
                    </div>
                  </Navbar.Brand>
                </div>
                <div className="d-flex">
                  <Form inline="true" id="navbar-search-form" className="d-flex flex-shrink-1" onSubmit={doSearch}>
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      className="mr-2"
                      id="recipe-search"
                      defaultValue={pageInfo?.searchQuery}
                      onChange={(event) => setSearchQuery(event.target.value)}
                    />
                    <Button id="recipe-search-button" onClick={doSearch} variant="secondary">
                      <FaSearch />
                    </Button>
                  </Form>
                  <div className="me-1 ps-1" id="hamburger-menu-icon-container">
                    <div
                      id="hamburger-menu-icon"
                      role="button"
                      tabIndex={0}
                      onClick={toggleTopMenu}
                      onKeyPress={toggleTopMenu}
                    >
                      <FaBars size="32" />
                    </div>
                  </div>

                  {isUserSignedIn(userData) ? (
                    <>
                      <div className="d-flex justify-content-end navbar-account-link-top" id="navbar-signin">
                        <span
                          className="local-link navbar-signin-text"
                          role="button"
                          tabIndex={0}
                          onClick={toggleAccountMenu}
                          onKeyPress={toggleAccountMenu}
                        >
                          My Account <FaCaretDown />
                        </span>
                        <AccountMenu />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex justify-content-end navbar-account-link-top" id="navbar-signin">
                        <Button variant="link" className="local-link navbar-signin-text" onClick={showSignIn}>
                          Sign In
                        </Button>
                      </div>
                      <div
                        className="d-flex justify-content-end navbar-account-link-top align-self-start"
                        id="navbar-signup"
                      >
                        <Button variant="link" className="local-link navbar-signin-text" onClick={showSignUp}>
                          Sign Up
                        </Button>
                      </div>
                    </>
                  )}

                  <Modal show={isAccountModalOpen}>
                    <AccountModal />
                  </Modal>
                </div>
              </div>
            </div>
            <TopMenu />
            <div id="site-navbar-menu">
              <div id="basic-navbar-nav" className="d-flex justify-content-center flex-shrink-1">
                <div className="d-flex justify-content-end">
                  <Nav.Link href="/">Home</Nav.Link>
                </div>
                <div className="d-flex justify-content-end">
                  <Nav.Link href="/cookbook">Cookbook</Nav.Link>
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  className="d-flex justify-content-end site-navbar-menu-option nav-link"
                  onClick={clickSavedRecipes}
                  onKeyPress={clickSavedRecipes}
                >
                  Saved Recipes
                </div>
              </div>
            </div>
          </div>
        </Navbar>
        {additionalNavBar === undefined ? (
          <></>
        ) : (
          <Navbar id="site-navbar-additional">
            <div className="d-flex justify-content-center">{additionalNavBar}</div>
          </Navbar>
        )}
      </div>
    </>
  );
};

export default CustomNavbar;
